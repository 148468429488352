import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import InfoLayout from "../components/layout/info"
import { Link } from "gatsby"
const shortcodes = { Link } // Provide common components here

export default function Template({
  data: {mdx},
}) {

  /**
   * ステータスタグを取得する
   * @param {*} status done, processing, others
   * @returns tag
   */
  const getStatusTag = (status) => {
    if (status === "done") {
      return (<span className="tag is-success m-3 p-3">完了</span>)
    }
    else if (status === "processing") {
      return (<span className="tag is-warning m-3 p-3">実施中</span>)
    }
    else {
      return (<span></span>)
    }
  }
  const statusTag = getStatusTag(mdx.frontmatter.status)
  return (
    <InfoLayout>
      <div className="blog-post-container">
        <div className="blog-post">
          <h1 className="title">{mdx.frontmatter.title}{statusTag}</h1>
          <MDXProvider components={shortcodes}>
            <MDXRenderer className="blog-post-content">{mdx.body}</MDXRenderer>
          </MDXProvider>
        </div>
      </div>
    </InfoLayout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        date(formatString: "YYYY年MM月DD日")
        slug
        title
        status
      }
    }
  }
`